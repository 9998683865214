@mixin ripple() {
  position: relative;

  &:after, &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
  }

  &:after {
    transition: all .2s;
  }

  &:hover:before {
    background: rgba(255, 255, 255, .1);
  }

  &:active:after, &:focus:after {
    background: rgba(255, 255, 255, .25);
  }
}