$primary-color: #0CAAD0;
$primary-light-color: #91D8F7;
$primary-darker-color: #33B5E4;
$primary-darkish-color: #2EA5D1;
$primary-dark-color: #057E9D;
$green: #A6CE38;
$red: #ff493d;

$secondary-text-on-light-background: rgba(0, 0, 0, .6);

$primary-text-on-dark-background: #fff;
$primary-text-on-light-background: #057E9D;

$primary-hint-on-dark-background: rgba(255, 255, 255, .5);
$border: rgba(88, 88, 88, 0.62);

$primary-text-hover-on-light-background: #91D8F7;

$content-padding: 56px;

$content-max-width: 600px;
$content-max-width-desktop: 1088px;

$desktop-at: 479px;
