@import "vars";
@import "button";
@import "selectric";
@import "datepicker";


.hidden, [hidden] {
  display: none;
}

// SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(5, 146, 178, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(5, 146, 178, 0.87);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0589a9;
}

.mdc-select--smaller {
  max-width: 150px;
  margin-left: 10px;
}

.small-screen {
  display: none;
}

.no-small-screen {
  display: initial;
}

a {
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 1440px) and (min-width: 1025px) {
  .mdc-layout-grid {
    &__cell--span-1-small-desktop-none {
      display: none;
    }

    &__cell--span-5 {
      grid-column-end: span 6 !important;
    }
  }
  .mdc-select--smaller {
    max-width: 120px;
  }
}

@media (max-width: 1024px) and (min-width: 840px) {
  .mdc-layout-grid {
    &__cell--span-12-small-desktop {
      grid-column-end: span 12 !important;
    }
  }
}

@media (max-width: 839px) and (min-width: 480px) {
  .mdc-layout-grid {
    &__cell--span-4-tablet {
      grid-column-end: span 4 !important;
    }

    &__cell--span-3-tablet {
      grid-column-end: span 3 !important;
    }

    &__cell--span-2-tablet {
      grid-column-end: span 2 !important;
    }
  }

}

@media (max-width: 839px) and (min-width: 480px) {
  .no-small-screen {
    display: none;
  }
  .small-screen {
    display: initial;
  }
}

@media (max-width: 479px) {
  .no-small-screen {
    display: none;
  }
  .small-screen {
    display: initial;
  }
}

.banner--custom {
  display: inline-block;
  width: 100% !important;
}


.display-container {
  display: flex !important;
  flex-wrap: nowrap;
  width: 200%;
  min-height: 100%;
}

.page {
  min-height: 100%;
  overflow-y: auto;
  width: 50%;
  flex-basis: 100%;
  //float: left;
  -webkit-overflow-scrolling: touch;

  &__inner {
    display: inline-block;
    width: calc(100% - 32px);
  }

  #withdraw-link, #deposit-link, #summary-link, #invesments-link, #profile-link {
    cursor: pointer;
    min-height: 100%;
  }

  &--next {
    transform: translate(0%, 0);
    z-index: 0;
    transition: transform .6s ease, box-shadow 1s ease;
  }

  &--next.active {
    transform: translate(-100%, 0);
    z-index: 0;
  }

  &--prev.not-active {
    transform: translate(-100%, 0);
    z-index: 0;
  }

  &--prev {
    transform: translate(0, 0);
    z-index: 0;
    transition: transform .6s ease, box-shadow 1s ease;
  }
}